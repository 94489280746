<template>
    <div>
        <div class="columns is-mobile is-multiline">
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Name') }}</label>
                    <div class="control">
                        <input class="input" type="text" v-model="merit.name" disabled>
                    </div>
                </div>
            </div>

            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Type') }}</label>
                    <div class="control">
                        <input class="input" type="text" v-model="merit.type" disabled>
                    </div>
                </div>
            </div>

            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Points') }}</label>
                    <div class="control">
                        <input class="input" type="text" v-model="merit.points" disabled>
                    </div>
                </div>
            </div>

            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Program') }}</label>
                    <div class="control" v-if="merit.program">
                        <input class="input" type="text" v-model="merit.program.name" disabled>
                    </div>
                </div>
            </div>
        </div>
        <div class="medal columns is-mobile is-full-mobile">
            <img v-if="merit.image"
                 :src="'/storage/' + merit.image" alt="Imagen de ejemplo">
        </div>
    </div>
</template>

<script>
export default {
    name: 'Show',
    inject: ['route', 'http', 'i18n'],
    data() {
        return {
            merit: {},
        };
    },

    mounted() {
        this.fetchData();
    },

    methods: {
        fetchData() {
            this.http
                .get(this.route('merit.show', { merit: this.$route.params.merit }))
                .then(({ data }) => {
                    this.merit = data.merit;
                })
                .catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss" scoped>
.medal {
    width: 10rem;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}
@media (max-width: 748px)
{
    .medal{
        width: 5.5rem;
    }
}
</style>
